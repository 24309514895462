import React, { useState, useEffect, useCallback } from 'react';
import { ChevronDown, Linkedin, Github, ExternalLink } from 'lucide-react';
import profilePic from './images/profile_pic.JPG';
import spaceman from './images/spaceman secure.jpg';
import minesweeper from './images/minesweeper.png';
import socalbeach from './images/beachfinder.png';
import athena from './images/athena.png';
import planmyday from './images/planmyday.png';
import cafedotcalm from './images/cafedotcalm.jpeg';
import FadeInSection from './FadeInSection';

const Navbar = ({ activeSection, setActiveSection }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
        setIsVisible(false);
      } else { // if scroll up show the navbar
        setIsVisible(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
      setIsScrolled(window.scrollY > 50);
    }
  }, [lastScrollY]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [controlNavbar]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveSection(sectionId);
  };

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled ? 'bg-white bg-opacity-70 backdrop-blur-md shadow-md' : 'bg-transparent'
    } ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="container mx-auto px-6 py-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-light">Mohaimen Syed</h1>
          <div className="space-x-8">
            {['Home', 'About', 'Experience', 'Projects', 'Certifications', 'Contact'].map((item) => (
              <button
                key={item.toLowerCase()}
                onClick={() => scrollToSection(item.toLowerCase())}
                className={`text-sm font-light transition-colors duration-300 ${
                  activeSection === item.toLowerCase() ? 'text-gray-900' : 'text-gray-500 hover:text-gray-900'
                }`}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

const Hero = () => (
  <section id="home" className="h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 to-gray-100">
    <div className="text-center">
      <div className="mb-8 animate-fade-in-up">
        <img 
          src={profilePic} 
          alt="Mohaimen Syed" 
          className="w-48 h-48 rounded-full mx-auto shadow-lg object-cover"
        />
      </div>
      <h1 className="text-6xl font-light mb-4 animate-fade-in-up animation-delay-300">
        Hello, I'm Mohaimen Syed
      </h1>
      <p className="text-xl text-gray-600 mb-8 animate-fade-in-up animation-delay-600">
        Software Developer & AI/ML Engineer
      </p>
      <div className="flex justify-center space-x-4 mb-8 animate-fade-in-up animation-delay-900">
        <a href="https://www.linkedin.com/in/mohaimensyed/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-blue-500 transition-colors duration-300">
          <Linkedin size={24} />
        </a>
        <a href="https://github.com/mohaimensyed" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900 transition-colors duration-300">
          <Github size={24} />
        </a>
      </div>
      <ChevronDown size={32} className="text-gray-400 animate-bounce mx-auto cursor-pointer" onClick={() => document.getElementById('about').scrollIntoView({ behavior: 'smooth' })} />
    </div>
  </section>
);

const About = () => (
  <section id="about" className="py-20 bg-white">
    <div className="container mx-auto px-6">
      <h2 className="text-4xl font-light mb-12 text-center">About Me</h2>
      <div className="max-w-3xl mx-auto">
        <p className="text-xl text-gray-600 leading-relaxed">
          I'm a Software Engineer with a strong foundation in Computer Science from the University of Southern California. With expertise in automation, AI/ML, and data processing, I'm passionate about developing innovative solutions using cutting-edge technologies. My experience spans from creating AI chatbots to implementing classification models and optimizing data processing workflows.
        </p>
      </div>
    </div>
  </section>
);

const Experience = () => (
  <section id="experience" className="py-20 bg-gray-50">
    <div className="container mx-auto px-6">
      <h2 className="text-4xl font-light mb-12 text-center">Experience</h2>
      <div className="max-w-4xl mx-auto space-y-12">
        {[
          {
            title: "Software Engineer - Automation",
            company: "Global Music Rights",
            location: "Los Angeles, CA",
            date: "05/23 – Present",
            responsibilities: [
              "Developed an AI chatbot integrating Azure AI Search, LangChain, and Azure OpenAI for streamlined business operations.",
              "Implemented classification models using SparkML, improving matched data accuracy.",
              "Utilized Databricks, Python, and PySpark to enhance data cleaning and matching efficiency."
            ]
          },
          {
            title: "Software Engineer - Intern",
            company: "Global Music Rights",
            location: "Los Angeles, CA",
            date: "06/22 – 08/22",
            responsibilities: [
              "Developed automated testing applications using Python and Selenium Web Driver.",
              "Established automated testing strategies for web applications, APIs, and MS SQL databases.",
              "Integrated automated tests into CI/CD pipeline using Azure DevOps and BrowserStack."
            ]
          },
          {
            title: "Software Engineer – Intern",
            company: "VNTANA",
            location: "Van Nuys, CA",
            date: "05/21 – 07/21",
            responsibilities: [
              "Authored Python application for automated testing of 3D asset optimization.",
              "Created and analyzed 3D models using CLO and Blender."
            ]
          }
        ].map((job, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
            <h3 className="text-2xl font-light mb-2">{job.title}</h3>
            <p className="text-gray-600 mb-2">{job.company}, {job.location} | {job.date}</p>
            <ul className="list-disc list-inside text-gray-600">
              {job.responsibilities.map((resp, respIndex) => (
                <li key={respIndex}>{resp}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  </section>
);
const Projects = () => {
  const projects = [
    {
      id: 1,
      title: "Athena",
      image: athena, 
      link: "#",
      description: "Athena Personal AI Assistant is an advanced AI-powered personal assistant that leverages document understanding and natural language processing to provide intelligent responses. The system combines semantic chunking, text embedding with OpenAI's ada model, FAISS vector storage, the Llama chat model from Ollama, and LangChain's RAG capabilities, all wrapped in a user-friendly Streamlit interface for seamless interaction.[RELEASE COMING SOON]"
    },
    {
      id: 2,
      title: "Plan My Day",
      image: planmyday, 
      link: "https://github.com/mohaimensyed/plan-my-day",
      description: "Plan My Day is designed for the Databricks DATA + AI Summit 2024. It helps users plan their day by leveraging data from open street maps and advanced AI models."
    },
    {
      id: 3,
      title: "Spaceman Secure",
      image: spaceman,
      link: "https://github.com/mohaimensyed/spaceman-secure/",
      description: "Spaceman Secure is an IoT-based security system that uses a Raspberry Pi, ultrasonic sensors, and MQTT protocol to detect nearby presence and manage access control. It alerts subscribers to potential intruders, handles access requests via a physical button and password system, and provides visual and audio feedback through LEDs, an LCD display, and a buzzer."
    },
    {
      id: 4,
      title: "CafeDotCalm",
      image: cafedotcalm,
      link: "https://devpost.com/software/cafedotcalm",
      description: "HACKSC 2021 - A virtual space café that anyone can join. It's a simple and easy way to quickly interact with others around the globe, with the same calming vibe of a morning at the coffee shop."
    },
    {
      id: 5,
      title: "SoCalBeach4Life",
      image: socalbeach,
      link: "https://github.com/mohaimensyed/SoCalBeach4Life",
      description: "SoCalBeach4Life is an Android mobile app that helps users discover nearby beaches in Southern California using their geolocation and the Google Maps API. Upon selecting a beach, the app provides convenient information about nearby parking options and recommends the top 5 restaurants in the vicinity, all developed using Java in Android Studio."
    },
    {
      id: 6,
      title: "Minesweeper",
      image: minesweeper,
      link: "https://github.com/mohaimensyed/Minesweeper",
      description: "An implementation of the classic Minesweeper game for Android devices, developed using Android Studio and Java."
    }

  ];

return (
    <section id="projects" className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-light mb-12 text-center">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project) => (
            <div key={project.id} className="bg-gray-50 rounded-lg shadow-sm overflow-hidden transition-all duration-300 hover:shadow-md transform hover:scale-105">
              <img src={project.image} alt={project.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h3 className="text-2xl font-light mb-2">{project.title}</h3>
                <p className="text-gray-600 mb-4">{project.description}</p>
                <a 
                  href={project.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center text-blue-500 hover:text-blue-700 transition-colors duration-300"
                >
                  View Project <ExternalLink size={16} className="ml-1" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Certifications = () => (
  <section id="certifications" className="py-20 bg-gray-50">
    <div className="container mx-auto px-6">
      <h2 className="text-4xl font-light mb-12 text-center">Certifications</h2>
      <div className="max-w-3xl mx-auto space-y-6">
        {[
          {
            title: "Databricks Certified Machine Learning Associate",
            issuer: "Databricks",
            date: "May 2024",
            link: "https://credentials.databricks.com/f69eda0f-bfe5-47ee-862b-ab721b7d5ab0"
          },
          {
            title: "Neural Networks and Deep Learning",
            issuer: "Coursera",
            date: "Sept 2024",
            link: "https://www.coursera.org/account/accomplishments/verify/4W1D499P04IU"
          },
          {
            title: "Accreditation in Databricks Fundamentals in Gen AI and Lakehouse",
            issuer: "Databricks",
            date: "May 2024",
            link: "https://credentials.databricks.com/036cebd4-7d54-4263-9e46-3b0d31427fdc#gs.ge3cvk"
          }
        ].map((cert, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="flex justify-between items-start">
              <div>
                <p className="text-xl text-gray-800">{cert.title}</p>
                <p className="text-sm text-gray-600 mt-1">Issued by {cert.issuer} on {cert.date}</p>
              </div>
              <a href={cert.link} className="text-blue-500 hover:text-blue-700 transition-colors duration-300" target="_blank" rel="noopener noreferrer">
                <ExternalLink size={20} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const Contact = () => (
  <section id="contact" className="py-20 bg-white">
    <div className="container mx-auto px-6">
      <h2 className="text-4xl font-light mb-12 text-center">Get in Touch</h2>
      <form className="max-w-lg mx-auto">
        <div className="mb-6">
          <input type="text" placeholder="Name" className="w-full px-4 py-3 border-b border-gray-300 focus:border-gray-900 transition-colors duration-300" />
        </div>
        <div className="mb-6">
          <input type="email" placeholder="Email" className="w-full px-4 py-3 border-b border-gray-300 focus:border-gray-900 transition-colors duration-300" />
        </div>
        <div className="mb-6">
          <textarea placeholder="Message" rows="4" className="w-full px-4 py-3 border-b border-gray-300 focus:border-gray-900 transition-colors duration-300"></textarea>
        </div>
        <button type="submit" className="w-full bg-gray-900 text-white py-3 rounded-lg hover:bg-gray-800 transition-colors duration-300">Send Message</button>
      </form>
    </div>
  </section>
);

const App = () => {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'about', 'experience', 'projects', 'certifications', 'contact'];
      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen">
      <Navbar activeSection={activeSection} setActiveSection={setActiveSection} />
      <FadeInSection>
        <Hero />
      </FadeInSection>
      <FadeInSection>
        <About />
      </FadeInSection>
      <FadeInSection>
        <Experience />
      </FadeInSection>
      <FadeInSection>
        <Projects />
      </FadeInSection>
      <FadeInSection>
        <Certifications />
      </FadeInSection>
      <FadeInSection>
        <Contact />
      </FadeInSection>
      <footer className="bg-gray-900 text-white py-8 text-center">
        <p className="text-sm font-light">&copy; 2024 Mohaimen Syed. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;