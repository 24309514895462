import React, { useState, useEffect } from 'react';
import useInView from './useInView';

const FadeInSection = ({ children }) => {
  const [ref, isInView] = useInView({
    threshold: 0.1,
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (isInView) {
      timer = setTimeout(() => {
        setIsVisible(true);
      }, 300); // Increased delay to 300ms
    } else {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 300); // Increased delay to 300ms for exiting as well
    }
    return () => clearTimeout(timer);
  }, [isInView]);

  return (
    <div ref={ref} className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}>
      {children}
    </div>
  );
};

export default FadeInSection;